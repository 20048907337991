.navigation-cover {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: var(--mainColor);
}

.navigation-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  height: 64px;
}

.logo_imgs {
  height: 48px;
  object-fit: cover;
}

.nav-bar-link {
  height: 64px;
  vertical-align: middle;
  align-items: center;
  padding: 22px;
  color: var(--textColor);
  text-decoration: none;
}

.nav-bar-link:hover {
  color: var(--mainColor);
  background-color: var(--textColor);
}

.navigation-container .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  display: none;
  opacity: 0;
  font-size: 1.8rem;
}

.navigation {
  display: flex;
  justify-content: flex-end;
}

nav {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 1024px) {
  .navigation-container .nav-btn {
    display: block;
    opacity: 1;
  }

  .navigation-container nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    transform: translateY(-100vh);
  }

  .navigation-container .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .nav-bar-link {
    font-size: 1.5rem;
  }
}
