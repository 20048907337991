.block_cover {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: calc(100vh - 64px);
  width: 100%;
}

.section_container {
  min-height: calc(100vh);
  padding-top: 100px;
  max-width: 1024px;
  width: 100%;
}

.section_title {
  text-align: left;
  font-size: 44px;
  font-family: "Helvetica Neue LT BD";
  letter-spacing: -2.2px;
  color: #000000;
  opacity: 1;
}
.mainblock-container {
  margin-top: 2rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  vertical-align: top;


  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;

}

.container_wrapping {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-bottom: 151px;
  gap: 50px;
}

.mainblock-left {
  display: flex;
  width: 512px;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  vertical-align: top;
  padding-left: 5rem;
  padding-right: 2rem;
}

.mainblock-right {
  display: flex;
  width: 512px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-right: 5rem;
  padding-left: 2rem;
}

.mainblock {
  display: flex;
  max-width: 512px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  vertical-align: top;
  opacity: 1;
  padding-right: 2rem;
  padding-left: 2rem;
}

.tiled_images {
  width: 180px;
  height: 180px;
  margin-top: 30px;
  margin-bottom: 30px;
  object-fit: cover;
}

.work-title {
  text-align: center;
  font-size: 32px;
  font-family: "Helvetica Neue LT BD";
  letter-spacing: -1.6px;
  color: var(--textColor);
}

.title {
  text-align: right;
  font-size: 44px;
  letter-spacing: -2.2px;
  color: var(--textColor);
  opacity: 1;
}

.subtitle {
  text-align: right;
  width: 100%;
  font-size: 18px;
  letter-spacing: 0px;
  color: var(--textColor);
  opacity: 1;
  font-family: "Helvetica Neue LT";
}

.description {
  text-align: justify;
  width: 100%;
  font-size: 18px;
  letter-spacing: 0px;
  color: var(--textColor);
  opacity: 1;
  font-family: "Helvetica Neue LT";
}

.rounded_imgs {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  object-fit: cover;
}

.img_thumbnails {
  width: 180px;
  height: 180px;
  object-fit: cover;
}

.my_buttons {
  width: 196px;
  height: 48px;
  border: 2px solid var(--textColor);
  border-radius: 28px;
  opacity: 1;
  margin-top: 31px;
  margin-bottom: 31px;
  margin-left: 11px;
  margin-right: 11px;
  text-align: center;
  font-size: 24px;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
}

.btn_left {
  background: var(--textColor) 0% 0% no-repeat padding-box;
  color: var(--mainColor);
}
.btn_right {
  background: var(--mainColor) 0% 0% no-repeat padding-box;
  color: var(--textColor);
}

.button-container {
  display: flex;
  justify-content: space-evenly;
}

.rounded_3d_imgs {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  object-fit: cover;
  opacity: 1;
  background: transparent;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1024px) {
  .mainblock-container {
    flex-direction: column;
  }

  .mainblock-left {
    align-items: center;
    padding-right: 0px;
    padding-left: 0px;
    width: calc(3 * 100vw / 4);
  }

  .mainblock-right {
    align-items: center;
    padding-right: 0px;
    padding-left: 0px;
    width: calc(3 * 100vw / 4);
  }

  .mainblock {
    align-items: center;
    padding-right: 0px;
    padding-left: 0px;
    width: calc(3 * 100vw / 4);
  }

  .subtitle {
    text-align: center;
  }

  .button-container {
    display: flex;
    flex-direction: column;
  }

  .container_wrapping {
    flex-direction: row;
  }

  .tiled_images {
    width: 120px;
    height: 120pxpx;
    object-fit: cover;
  }
}
