
/* Bold Weight */
@font-face {
  font-family: "Helvetica LT Bold";
  src: url("./fonts/Helvetica_LT_Bold.ttf") format("truetype");
}

/* Regular Weight */
@font-face {
  font-family: "Helvetica LT Regular";
  src: url("./fonts/Helvetica_LT_Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Neue LT BD";
  src: url("./fonts/HelveticaNeueLTCom-Bd.ttf") format("truetype");
}


@font-face {
  font-family: "Helvetica Neue LT";
  src: url("./fonts/HelveticaNeueLTCom-Lt.ttf") format("truetype");
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Helvetica Neue LT BD";
}

:root {
  --mainColor: #ffffff;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #000000;
}

body {
  margin: 0;
  font-family: "Helvetica LT Bold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, "Helvetica Neue LT BD";
}


